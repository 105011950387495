
import {
    InputLabel,
    TextField,
    Box
} from '@mui/material';
import { SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import {InputProps, TextFieldProps} from '@mui/material';

// ----------------------------------------------------------------------

const CssTextField = styled(TextField)(({ theme }) => ({
    
    '& 	.MuiOutlinedInput-input': {
      backgroundColor: '#F1F1F1',
      color: 'black'
    }
  }));
  
  // ----------------------------------------------------------------------

export default function CustomTextField(props : TextFieldProps) {

    return (
        <Box display='flex' flexDirection='column' alignItems='flex-start' gap='8px' color='red !important'>
            <InputLabel htmlFor="input-with-icon-adornment" sx={{ color: '#171520', mt: 1, fontWeight: 500 }}>
                {props.title}
            </InputLabel>
            <CssTextField {...props} fullWidth sx={{ mb: 2, ...props.sx }} />
        </Box>
    )
}