import { Theme } from '@mui/material/styles';
//Redux
import { useDispatch, useSelector } from 'redux/store';

// ----------------------------------------------------------------------
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    environment_outlined: true;
    environment_contained: true;
    environment_text: true;
  }
}
export default function Button(theme: Theme) {

  const { environment } = useSelector((state) => state.nuDetectState);

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none'
          }
        },
        sizeLarge: {
          height: 48
        }
      },
      variants: [
        {
          props: { variant: 'environment_outlined' },
          style: {
            textTransform: 'none',
            border: `3px solid ${environment?.color || theme.palette.primary.main}`,
            color: environment?.color || theme.palette.primary.main,
          }
        },
        {
          props: { variant: 'environment_contained' },
          style: {
            color: '#FFF',
            boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px',
            backgroundColor: environment?.color || theme.palette.primary.main
          }
        },
        {
          props: { variant: 'environment_text' },
          style: {
            color: environment?.color || theme.palette.primary.main,
          }
        }
      ],
    }
  };
}
