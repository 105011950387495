const ObjetoLenguage = {
    'en': {
        'general.continue': 'CONTINUE',
        'general.actions': 'Actions',

        'login.texto-1': "Welcome to Cyber Bank's mobile banking app. If you are already a customer with an existing bank card, you can sign up below for mobile access.",
        'login.texto-2': 'SIGN UP',
        'login.texto-3': 'Do you already have an account?',
        'login.texto-4': 'APPLY FOR A CREDIT CARD',
        'login.texto-5': "Discover what's new on the 2022 collection",
        'login.texto-6': 'LOCATIONS',
        'login.texto-7': 'HELP',
        'login.texto-8': 'CONTACTS US',
        'login.texto-9': 'PRIVACY POLICY',
        'login.texto-10': 'Sign In',

        'apply-for-credit-about.texto-1': 'ABOUT YOU',
        'apply-for-credit-about.texto-2': 'First Name',
        'apply-for-credit-about.texto-3': 'First name is required',
        'apply-for-credit-about.texto-4': 'Middle Initial (optional)',
        'apply-for-credit-about.texto-5': 'Last Name',
        'apply-for-credit-about.texto-6': 'Last name is required',
        'apply-for-credit-about.texto-7': 'Date of Birth',
        'apply-for-credit-about.texto-8': 'Enter the month of your date of birth',
        'apply-for-credit-about.texto-9': 'Enter the day of your date of birth',
        'apply-for-credit-about.texto-10': 'Enter the year your date of birth',
        'apply-for-credit-about.texto-11': 'Citizenship',
        'apply-for-credit-about.texto-12': 'SELECT A CITIZENSHIP',
        'apply-for-credit-about.texto-13': 'Select an option from the list',
        'apply-for-credit-about.texto-14': 'CITIZEN',
        'apply-for-credit-about.texto-15': 'RESIDENT',
        'apply-for-credit-about.texto-16': 'NON-RESIDENT',
        'apply-for-credit-about.texto-17': 'Last 4 Digital of Social Security Number',
        'apply-for-credit-about.texto-18': 'Enter the last 4 digits of your social security number',
        'apply-for-credit-about.texto-19': 'CONTINUE',

        'apply-for-credit-income.texto-1': 'YOUR INCOME',
        'apply-for-credit-income.texto-2': 'Occupation status',
        'apply-for-credit-income.texto-3': 'SELECT YOUR STATUS',
        'apply-for-credit-income.texto-4': 'Select an option from the list',
        'apply-for-credit-income.texto-5': 'EMPLOYED',
        'apply-for-credit-income.texto-6': 'NOT EMPLOYED - UNEMPLOYED',
        'apply-for-credit-income.texto-7': 'NOT EMPLOYED - HOMEMAKER',
        'apply-for-credit-income.texto-8': 'RETIRED',
        'apply-for-credit-income.texto-9': 'SELF-EMPLOYED - PARTNERSHIP',
        'apply-for-credit-income.texto-10': 'SELF-EMPLOYED - COMPANY DIRECTOR',
        'apply-for-credit-income.texto-11': 'SELF-EMPLOYED - SOLETRADER',
        'apply-for-credit-income.texto-12': 'Gross annual income',
        'apply-for-credit-income.texto-13': 'Enter an amount',
        'apply-for-credit-income.texto-14': "This includes any salary, self employed work or other regular payments you'll receive in the next 12 months e.g. child maintenance, pensions or guaranteed child, incapacity or care benefits",
        'apply-for-credit-income.texto-15': 'Monthly mortgage / rent payments',
        'apply-for-credit-income.texto-16': 'Monthly credit card / loan payments',
        'apply-for-credit-income.texto-17': 'CONTINUE',

        'apply-for-credit-contact.texto-1': 'CONTACTING YOU',
        'apply-for-credit-contact.texto-2': 'Email address',
        'apply-for-credit-contact.texto-3': 'Email address is required',
        'apply-for-credit-contact.texto-4': 'Enter a valid email address',
        'apply-for-credit-contact.texto-5': "We'll email our decision to you, but we won't use this to contact you about anything else.",
        'apply-for-credit-contact.texto-6': 'Phone number',
        'apply-for-credit-contact.texto-7': 'Enter a 10 digit number',
        'apply-for-credit-contact.texto-8': 'Street address',
        'apply-for-credit-contact.texto-9': 'Street address is required',
        'apply-for-credit-contact.texto-10': 'City',
        'apply-for-credit-contact.texto-11': 'City is required',
        'apply-for-credit-contact.texto-12': 'Zip Code',
        'apply-for-credit-contact.texto-13': 'Zip Code is required',
        'apply-for-credit-contact.texto-14': 'APPLY FOR A CREDIT CARD',

        'apply-for-credit-verification.texto-1': 'YOUR REQUEST HAS BEEN SENT. YOU’LL GET A DECISION BY EMAIL SHORTLY',
        'apply-for-credit-verification.texto-2': 'REFERENCE NUMBER:',

        'sign-up-card-details.texto-1' : 'BANK CARD DETAILS',
        'sign-up-card-details.texto-2' : 'Please enter your existing Bank Card number:',
        'sign-up-card-details.texto-3' : 'Credit or debit card number',
        'sign-up-card-details.texto-4' : 'Enter a 16 digit card number',
        'sign-up-card-details.texto-5' : 'CONTINUE',

        'sign-up-card-phone.texto-1' : 'PHONE NUMBER',
        'sign-up-card-phone.texto-2' : 'Please enter your phone number registered with the bank:',
        'sign-up-card-phone.texto-3' : 'Phone number',
        'sign-up-card-phone.texto-4' : 'Enter a 10 digit number',
        'sign-up-card-phone.texto-5' : 'CONTINUE',

        'sign-up-access.texto-1': 'ACCESS',
        'sign-up-access.texto-2': 'Please specify a new user ID and password for your mobile banking:',
        'sign-up-access.texto-3': 'Choose a User ID',
        'sign-up-access.texto-4': 'Please enter your user ID',
        'sign-up-access.texto-5': "Choose a Password",
        'sign-up-access.texto-6': 'Please enter your password',
        'sign-up-access.texto-7': 'Short passwords are easy to guess. Try one with at least 10 alphanumeric characters',
        'sign-up-access.texto-8': 'Re-enter the Password',
        'sign-up-access.texto-9': 'Your passwords didn´t match. Try again',
        'sign-up-access.texto-10': 'CONFIRM',
        'sign-up-access.texto-11': 'YOUR ACCOUNT HAS BEEN ACTIVATED',
        'sign-up-access.texto-12': 'Incorrect user ID. Try alphanumeric characters',

        'sign-in.texto-1': 'What is your ID?',
        'sign-in.texto-2': 'Please enter your user ID',
        'sign-in.texto-3': 'What is your Password?',
        'sign-in.texto-4': 'Incorrect password',
        'sign-in.texto-5': "FORGOT PASSWORD?",
        'sign-in.texto-6': 'Don´t have an account?',
        'sign-in.texto-7': 'SIGN IN',
        'sign-in.texto-8': 'LOCATIONS',
        'sign-in.texto-9': 'HELP',
        'sign-in.texto-10': 'CONTACTS US',
        'sign-in.texto-11': 'PRIVACY POLICY',
        'sign-in.texto-12': 'Sign Up',

        'sign-in-confirm.texto-1': 'We sent a code to your mobile phone',
        'sign-in-confirm.texto-2': 'Enter it below.',
        'sign-in-confirm.texto-3': 'Didn’t get the code?',
        'sign-in-confirm.texto-4': 'RESEND',
        'sign-in-confirm.texto-5': "CONTINUE",

        'forgot-password.texto-1': 'What is your user ID?',
        'forgot-password.texto-2': 'Please enter your user ID',
        'forgot-password.texto-3': 'Don´t have an account? Sign Up',
        'forgot-password.texto-4': 'RESET PASSWORD',
        'forgot-password.texto-5': "WE SENT YOU INSTRUCTIONS TO RESET YOUR PASSWORD TO YOUR EMAIL YOUR JE****GMAIL.COM. PLEASE CONTINUE FROM THERE",
        'forgot-password.texto-6': 'Didn’t received the email?',
        'forgot-password.texto-7': 'Change password',
        'forgot-password.texto-8': 'Enter a new password',
        'forgot-password.texto-9': 'Please enter your password',
        'forgot-password.texto-10': 'Short passwords are easy to guess. Try one with at least 10 alphanumeric characters',
        'forgot-password.texto-11': 'Confirm the new password',
        'forgot-password.texto-12': 'Your passwords didn´t match. Try again',
        'forgot-password.texto-13': 'CHANGE',
        'forgot-password.texto-14': 'Resend',

        'home.texto-1': 'My accounts',
        'home.texto-2': 'Dashboard',
        'home.texto-3': 'Products',
        'home.texto-4': 'Settings',
        'home.texto-5': 'Profile and Settings',
        'home.texto-6': 'My Profile',
        'home.texto-7': 'View and edit your personal information and contact details',
        'home.texto-8': 'Notifications',
        'home.texto-9': 'Edit the notifications you would like to receive and its frequency',
        'home.texto-10': 'Change Password',
        'home.texto-11': "Update your password to keep your account secure",
        'home.texto-12': 'Log Out',

        'my-accounts.texto-1': 'Cards',
        'my-accounts.texto-2': 'Balance',
        'my-accounts.texto-3': 'Deposits',
        'my-accounts.texto-4': 'Debits',
        'my-accounts.texto-5': 'Transfer',
        'my-accounts.texto-6': "Bill Pay",
        'my-accounts.texto-7': 'Pay Wallet',
        'my-accounts.texto-8': 'Deposit Check',
        'my-accounts.texto-9': 'Spending Report',
        'my-accounts.texto-10': 'Groceries',
        'my-accounts.texto-11': 'Electronics',
        'my-accounts.texto-12': 'Travels',
        'my-accounts.texto-13': 'Recent Transactions',
        'my-accounts.texto-14': 'Cable Co. Refund',
        'my-accounts.texto-15': 'Grocery Store',
        'my-accounts.texto-16': 'Sneakers Shop',
        'my-accounts.texto-17': 'Flight Compensation',
        'my-accounts.texto-18': "Medicine",
        'my-accounts.texto-19': 'ATM withdrawal',
        'my-accounts.texto-20': "Restaurant",

        'products.texto-1': 'Everyday Long Term Balance Transfer Credit Card',
        'products.texto-2': '0% interest on balance transfers for 31 months from account opening (2.75% balance transfer fee applies, min $5).',
        'products.texto-3': '0% interest on purchases for 3 months from account opening.',
        'products.texto-4': 'No monthly account fee.',

        'my-profile.texto-1': 'Personal and contact info',
        'my-profile.texto-2': 'Personal info',
        'my-profile.texto-3': 'Name ',
        'my-profile.texto-4': 'Address',
        'my-profile.texto-5': 'Street address',
        'my-profile.texto-6': 'Street address is required',
        'my-profile.texto-7': 'City',
        'my-profile.texto-8': 'City is required',
        'my-profile.texto-9': 'Zip Code',
        'my-profile.texto-10': 'Zip Code is required',
        'my-profile.texto-11': 'THE PERSONAL INFO WAS UPDATED SUCCESSFULLY',
        'my-profile.texto-12': 'Contact info',
        'my-profile.texto-13': 'Email',
        'my-profile.texto-14': 'Email address is required',
        'my-profile.texto-15': 'Enter a valid email address',
        'my-profile.texto-16': 'Phone number',
        'my-profile.texto-17': 'Enter a 10 digit number',
        'my-profile.texto-18': 'THE CONTACT INFO WAS UPDATED SUCCESSFULLY',

        'change-password.texto-1': 'Personal and contact info',
        'change-password.texto-2': 'CHANGE PASSWORD',
        'change-password.texto-3': 'Current password',
        'change-password.texto-4': 'Please enter your password',
        'change-password.texto-5': 'Incorrect password',
        'change-password.texto-6': 'Enter a new password',
        'change-password.texto-7': 'Please enter your new password',
        'change-password.texto-8': 'Short passwords are easy to guess. Try one with at least 10 alphanumeric characters',
        'change-password.texto-9': 'Confirm the new password',
        'change-password.texto-10': 'Please enter your new password',
        'change-password.texto-11': 'Your passwords didn´t match. Try again',
        'change-password.texto-12': 'Change Password',
        'change-password.texto-13': 'YOUR PASSWORD WAS SUCCESSFULLY CHANGED',

        'add-payee.texto-1': 'Add new payee',
        'add-payee.texto-2': 'Add payee',
        'add-payee.texto-3': 'Please fill payee info',
        'add-payee.texto-4': 'Account type',
        'add-payee.texto-5': 'Personal',
        'add-payee.texto-6': 'Business',
        'add-payee.texto-7': 'Payee name',
        'add-payee.texto-8': 'Payee name is required',
        'add-payee.texto-9': 'Company name',
        'add-payee.texto-10': 'Company name is required',
        'add-payee.texto-11': 'Account number',
        'add-payee.texto-12': 'Enter a 11 digit account number',
        'add-payee.texto-13': 'Zip Code',
        'add-payee.texto-14': 'Zip Code is required',
        'add-payee.texto-15': 'CONTINUE',
        'add-payee.texto-16': 'Please confirm payee info',
        'add-payee.texto-17': 'Edit',
        'add-payee.texto-18': 'ADD',
        'add-payee.texto-19': 'Payee successfully added',
        'add-payee.texto-20': 'THE PAYEE WAS SUCCESSFULLY ADDED',
        'add-payee.texto-21': 'ENTER THE COMPANY NAME',
        'add-payee.texto-22': 'ENTER THE PAYEE NAME',
        'add-payee.texto-23': 'Confirm payee',
        'add-payee.texto-24': 'CONFIRM PAYEE TO ADD',
        'add-payee.texto-25': 'Payee added',

        'transfer.texto-1': 'Make a Transfer',
        'transfer.texto-2': 'Transfer to',
        'transfer.texto-3': 'Select payee to transfer to',
        'transfer.texto-4': 'Add new payee',
        'transfer.texto-5': 'Select a Payee',
        'transfer.texto-6': 'Search a payee',
        'transfer.texto-7': 'Name, Account number',
        'transfer.texto-8': 'Frequent payees',
        'transfer.texto-9': 'Account N°',
        'transfer.texto-10': 'Personal',
        'transfer.texto-11': 'Business',
        'transfer.texto-12': 'Enter an amount',
        'transfer.texto-13': 'Amount',
        'transfer.texto-14': 'You do not have sufficient funds to proceed with this transfer',
        'transfer.texto-15': 'Remark',
        'transfer.texto-16': 'Transfer from',
        'transfer.texto-17': 'Select an account to transfer from',
        'transfer.texto-18': 'Confirm transfer',
        'transfer.texto-19': 'Select an Account',
        'transfer.texto-20': 'Savings Account',
        'transfer.texto-21': 'Checking Account',
        'transfer.texto-22': 'CONTINUE',
        'transfer.texto-23': 'YOUR TRANSFER HAS BEEN COMPLETED',
        'transfer.texto-24': 'Transferred to',
        'transfer.texto-25': 'Transferred from',
        'transfer.texto-26' : 'Enter received code',

        'bill-pay.texto-1': 'Make a Payment',
        'bill-pay.texto-2': 'Pay to',
        'bill-pay.texto-3': 'Select payee from the list below',
        'bill-pay.texto-4': 'Add new payee',
        'bill-pay.texto-5': 'Select a Payee',
        'bill-pay.texto-6': 'Search a payee',
        'bill-pay.texto-7': 'Name, Account number',
        'bill-pay.texto-8': 'Frequent payees',
        'bill-pay.texto-9': 'Account N°',
        'bill-pay.texto-10': 'Business',
        'bill-pay.texto-11': 'Enter an amount',
        'bill-pay.texto-12': 'Amount',
        'bill-pay.texto-13': 'You do not have sufficient funds to proceed with this payment',
        'bill-pay.texto-14': 'Remark',
        'bill-pay.texto-15': 'Select an account to pay from',
        'bill-pay.texto-16': 'Confirm payment',
        'bill-pay.texto-17': 'Select an Account',
        'bill-pay.texto-18': 'Savings Account',
        'bill-pay.texto-19': 'Checking Account',
        'bill-pay.texto-20': 'CONTINUE',
        'bill-pay.texto-21': 'YOUR PAYMENT HAS BEEN COMPLETED',
        'bill-pay.texto-22': 'Paid to',
        'bill-pay.texto-23': 'Paid from',
        'bill-pay.texto-24': 'Pay from',
    },
    'es': {
        'general.continue': 'CONTINUAR',
        'general.actions': 'Acciones',

        'login.texto-1': "Bienvenido a la aplicación de banco móvil de Cyber Bank. Si ya eres un cliente con una tarjeta de banco existente, puedes iniciar sesión abajo para acceso móvil. ",
        'login.texto-2': 'REGISTRARSE',
        'login.texto-3': '¿Ya tienes una cuenta?',
        'login.texto-4': 'APLICA PARA UNA TARJETA DE CRÉDITO',
        'login.texto-5': "Discover what's new on the 2022 collection",
        'login.texto-6': 'LOCACIONES',
        'login.texto-7': 'AYUDA',
        'login.texto-8': 'CONTÁCTANOS',
        'login.texto-9': 'PÓLIZA DE PRIVACIDAD',
        'login.texto-10': 'Iniciar Sesión',

        'apply-for-credit-about.texto-1': 'ACERCA DE TI',
        'apply-for-credit-about.texto-2': 'Primer Nombre',
        'apply-for-credit-about.texto-3': 'Primer nombre es requerido',
        'apply-for-credit-about.texto-4': 'Inicial del Segundo Nombre (opcional)',
        'apply-for-credit-about.texto-5': 'Apellido/s',
        'apply-for-credit-about.texto-6': 'Apellido/s es requerido',
        'apply-for-credit-about.texto-7': 'Fecha de Nacimiento',
        'apply-for-credit-about.texto-8': 'Ingresa el mes de tu fecha de nacimiento',
        'apply-for-credit-about.texto-9': 'Ingresa el día de tu fecha de nacimiento',
        'apply-for-credit-about.texto-10': 'Ingresa el año de tu fecha de nacimiento',
        'apply-for-credit-about.texto-11': 'Ciudadanía',
        'apply-for-credit-about.texto-12': 'SELECCIONA UNA CIUDADANÍA',
        'apply-for-credit-about.texto-13': 'Selecciona una opción de la lista',
        'apply-for-credit-about.texto-14': 'CIUDADANO',
        'apply-for-credit-about.texto-15': 'RESIDENTE',
        'apply-for-credit-about.texto-16': 'NO RESIDENTE',
        'apply-for-credit-about.texto-17': 'Últimos 4 Dígitos del Número de Seguridad Social',
        'apply-for-credit-about.texto-18': 'Ingresa los últimos 4 dígitos de tu número de seguridad social',
        'apply-for-credit-about.texto-19': 'CONTINUAR',

        'apply-for-credit-income.texto-1': 'TUS INGRESOS',
        'apply-for-credit-income.texto-2': 'Estatus de ocupación',
        'apply-for-credit-income.texto-3': 'SELECCIONA TU ESTATUS ',
        'apply-for-credit-income.texto-4': 'Selecciona una opción de la lista',
        'apply-for-credit-income.texto-5': 'EMPLEADO',
        'apply-for-credit-income.texto-6': 'NO EMPLEADO - DESEMPLEADO',
        'apply-for-credit-income.texto-7': 'NO EMPLEADO - AMO DE CASA',
        'apply-for-credit-income.texto-8': 'RETIRADO',
        'apply-for-credit-income.texto-9': 'TRABAJADOR POR CUENTA PROPIA - ASOCIACIÓN',
        'apply-for-credit-income.texto-10': 'TRABAJADOR POR CUENTA PROPIA - DIRECTOR DE COMPAÑÍA',
        'apply-for-credit-income.texto-11': 'TRABAJADOR POR CUENTA PROPIA - COMERCIANTE ÚNICO',
        'apply-for-credit-income.texto-12': 'Ingreso bruto anual',
        'apply-for-credit-income.texto-13': 'Ingresa un monto',
        'apply-for-credit-income.texto-14': "Esto incluye cualquier salario, trabajo por cuenta propia u otros pagos regulares que recibirás en los próximos 12 meses, por ejemplo, manutención de hijos, pensiones o prestaciones garantizadas por hijos, incapacidad o beneficios de cuidado",
        'apply-for-credit-income.texto-15': 'Hipoteca mensual / pagos de renta',
        'apply-for-credit-income.texto-16': 'Mensualidad de tarjeta de crédito / pagos de préstamo ',
        'apply-for-credit-income.texto-17': 'CONTINUAR',

        'apply-for-credit-contact.texto-1': 'CONTACTÁNDOTE',
        'apply-for-credit-contact.texto-2': 'Correo electrónico',
        'apply-for-credit-contact.texto-3': 'Correo electrónico es requerido',
        'apply-for-credit-contact.texto-4': 'Ingresa un correo electrónico válido',
        'apply-for-credit-contact.texto-5': "Te enviaremos nuestra decisión a tu correo electrónico, pero no la utilizaremos para contactarte por otro motivo.",
        'apply-for-credit-contact.texto-6': 'Número de teléfono',
        'apply-for-credit-contact.texto-7': 'Ingresa un número de 10 dígitos',
        'apply-for-credit-contact.texto-8': 'Dirección de calle',
        'apply-for-credit-contact.texto-9': 'Dirección de calle es requerida',
        'apply-for-credit-contact.texto-10': 'Ciudad',
        'apply-for-credit-contact.texto-11': 'Ciudad es requerida',
        'apply-for-credit-contact.texto-12': 'Código Postal',
        'apply-for-credit-contact.texto-13': 'Código Postal es requerido',
        'apply-for-credit-contact.texto-14': 'APLICAR PARA UNA TARJETA DE CRÉDITO',

        'apply-for-credit-verification.texto-1': 'TU PETICIÓN HA SIDO ENVIADA. RECIBIRÁS UNA DECISIÓN A TU CORREO ELECTRÓNICO EN BREVE',
        'apply-for-credit-verification.texto-2': 'NÚMERO DE REFERENCIA:',

        'sign-up-card-details.texto-1' : 'DETALLES DE TARJETA DE BANCO',
        'sign-up-card-details.texto-2' : 'Por favor ingresa su número de Tarjeta de Banco:',
        'sign-up-card-details.texto-3' : 'Número de tarjeta de crédito o débito',
        'sign-up-card-details.texto-4' : 'Ingresa un número de tarjeta de 16 dígitos',
        'sign-up-card-details.texto-5' : 'CONTINUAR',

        'sign-up-card-phone.texto-1' : 'NÚMERO DE TELÉFONO',
        'sign-up-card-phone.texto-2' : 'Por favor ingresa el número de teléfono registrado con el banco:',
        'sign-up-card-phone.texto-3' : 'Número de teléfono',
        'sign-up-card-phone.texto-4' : 'Ingresa un número de 10 dígitos',
        'sign-up-card-phone.texto-5' : 'CONTINUAR',

        'sign-up-access.texto-1': 'ACCESO',
        'sign-up-access.texto-2': 'Por favor especifica una nueva ID de usuario y una contraseña para tu banco móvil',
        'sign-up-access.texto-3': 'Escoge una ID de usuario',
        'sign-up-access.texto-4': 'Por favor ingrese su ID de usuario',
        'sign-up-access.texto-5': "Escoge una contraseña",
        'sign-up-access.texto-6': 'Por favor, introduce tu contraseña',
        'sign-up-access.texto-7': 'Las contraseñas cortas son fáciles de adivinar. Pruebe una con al menos 10 caracteres alfanuméricos',
        'sign-up-access.texto-8': 'Vuelva a ingresar la contraseña',
        'sign-up-access.texto-9': 'Tus contraseñas no coincidieron. Inténtalo de nuevo',
        'sign-up-access.texto-10': 'CONFIRMAR',
        'sign-up-access.texto-11': 'TU CUENTA HA SIDO ACTIVADA',
        'sign-up-access.texto-12': 'User ID incorrecto, favor de intentar con carácteres alfanúmericos.',

        'sign-in.texto-1': '¿Cuál es tu ID?',
        'sign-in.texto-2': 'Por favor ingresa su ID de usuario',
        'sign-in.texto-3': '¿Cuál es tu Contraseña?',
        'sign-in.texto-4': 'Contraseña incorrecta',
        'sign-in.texto-5': "¿OLVIDASTE TU CONTRASEÑA?",
        'sign-in.texto-6': '¿No tienes una cuenta?',
        'sign-in.texto-7': 'INICIAR SESIÓN',
        'sign-in.texto-8': 'LOCACIONES',
        'sign-in.texto-9': 'AYUDA',
        'sign-in.texto-10': 'CONTÁCTANOS',
        'sign-in.texto-11': 'PÓLIZA DE PRIVACIDAD',
        'sign-in.texto-12': 'Registrarse',

        'sign-in-confirm.texto-1': 'Te enviamos un código a tu número de teléfono',
        'sign-in-confirm.texto-2': 'Ingrésalo abajo.',
        'sign-in-confirm.texto-3': '¿No recibiste el código?',
        'sign-in-confirm.texto-4': 'REENVIAR',
        'sign-in-confirm.texto-5': "CONTINUAR",

        'forgot-password.texto-1': '¿Cuál es tu ID de usuario?',
        'forgot-password.texto-2': 'Por favor ingresa su ID de usuario',
        'forgot-password.texto-3': '¿No tienes una cuenta? Registrarse',
        'forgot-password.texto-4': 'RESTABLECER LA CONTRASEÑA',
        'forgot-password.texto-5': "TE ENVIAMOS INSTRUCCIONES PARA RESTABLECER TU CONTRASEÑA A TU CORREO ELECTRÓNICO YOUR JE****GMAIL.COM. POR FAVOR SIGUE DESDE AHÍ",
        'forgot-password.texto-6': '¿No recibiste el correo electrónico?',
        'forgot-password.texto-7': 'Cambiar contraseña',
        'forgot-password.texto-8': 'Introduce una nueva contraseña',
        'forgot-password.texto-9': 'Por favor, introduce tu contraseña',
        'forgot-password.texto-10': 'Las contraseñas cortas son fáciles de adivinar. Prueba una con al menos 10 caracteres alfanuméricos',
        'forgot-password.texto-11': 'Confirmar la nueva contraseña',
        'forgot-password.texto-12': 'Tus contraseñas no coincidieron. Inténtalo de nuevo',
        'forgot-password.texto-13': 'CAMBIAR',
        'forgot-password.texto-14': 'Reenviar',

        'home.texto-1': 'Mis cuentas',
        'home.texto-2': 'Tablero',
        'home.texto-3': 'Productos',
        'home.texto-4': 'Ajustes',
        'home.texto-5': 'Perfil y configuración',
        'home.texto-6': 'Mi Perfil',
        'home.texto-7': 'Ver y editar tu información personal y detalles de contacto',
        'home.texto-8': 'Notificaciones',
        'home.texto-9': 'Edita las notificaciones que te gustaría recibir y su frecuencia',
        'home.texto-10': 'Cambiar Contraseña',
        'home.texto-11': "Actualice su contraseña para mantener su cuenta segura",
        'home.texto-12': 'Cerrar Sesión',

        'my-accounts.texto-1': 'Tarjetas',
        'my-accounts.texto-2': 'Saldo',
        'my-accounts.texto-3': 'Depósitos',
        'my-accounts.texto-4': 'Débitos',
        'my-accounts.texto-5': 'Transferir',
        'my-accounts.texto-6': "Pago de Facturas",
        'my-accounts.texto-7': 'Cartera de pago',
        'my-accounts.texto-8': 'Depósito de Cheque',
        'my-accounts.texto-9': 'Reporte de Gastos',
        'my-accounts.texto-10': 'Comestibles',
        'my-accounts.texto-11': 'Electrónicos',
        'my-accounts.texto-12': 'Viajes',
        'my-accounts.texto-13': 'Transacciones Recientes',
        'my-accounts.texto-14': 'Reembolso Cable Co.',
        'my-accounts.texto-15': 'Tienda de abarrotes',
        'my-accounts.texto-16': 'Tienda de Tenis',
        'my-accounts.texto-17': 'Compensación de vuelo',
        'my-accounts.texto-18': "Medicina",
        'my-accounts.texto-19': 'Retiro de cajero automático',
        'my-accounts.texto-20': "Restaurante",

        'products.texto-1': 'Tarjeta de crédito de transferencia de saldo a largo plazo todos los días',
        'products.texto-2': '0% de interés en transferencias de saldo durante 31 meses desde la apertura de la cuenta (Se aplica una tarifa de transferencia de saldo del 2.75 %, mínimo $5).',
        'products.texto-3': '0% de interés en compras durante 3 meses desde la apertura de la cuenta.',
        'products.texto-4': 'Sin tarifa mensual de cuenta.',

        'my-profile.texto-1': 'Información personal y de contacto',
        'my-profile.texto-2': 'Información personal',
        'my-profile.texto-3': 'Nombre ',
        'my-profile.texto-4': 'Dirección',
        'my-profile.texto-5': 'Dirección de calle',
        'my-profile.texto-6': 'La dirección de la calle es obligatoria',
        'my-profile.texto-7': 'Ciudad',
        'my-profile.texto-8': 'Ciudad es requerida',
        'my-profile.texto-9': 'Código postal',
        'my-profile.texto-10': 'Se requiere el código postal',
        'my-profile.texto-11': 'LA INFORMACIÓN PERSONAL HA SIDO ACTUALIZADA CON ÉXITO',
        'my-profile.texto-12': 'Información de contacto',
        'my-profile.texto-13': 'Correo electrónico',
        'my-profile.texto-14': 'Correo electrónico es requerido',
        'my-profile.texto-15': 'Ingresa un correo electrónico válido',
        'my-profile.texto-16': 'Número de teléfono',
        'my-profile.texto-17': 'Ingresa un número de 10 dígitos',
        'my-profile.texto-18': 'LA INFORMACIÓN DE CONTACTO HA SIDO ACTUALIZADA CON ÉXITO',

        'change-password.texto-1': 'Información personal y de contacto',
        'change-password.texto-2': 'Cambiar Contraseña',
        'change-password.texto-3': 'Contraseña actual',
        'change-password.texto-4': 'Por favor ingresa tu contraseña',
        'change-password.texto-5': 'Contraseña incorrecta',
        'change-password.texto-6': 'Introduzca una nueva contraseña',
        'change-password.texto-7': 'Por favor ingresa tu nueva contraseña',
        'change-password.texto-8': 'Las contraseñas cortas son fáciles de adivinar. Prueba una con al menos 10 caracteres alfanuméricos',
        'change-password.texto-9': 'Confirmar la nueva contraseña',
        'change-password.texto-10': 'Por favor ingresa tu nueva contraseña',
        'change-password.texto-11': 'Tus contraseñas no coincidieron. Inténtalo de nuevo',
        'change-password.texto-12': 'Cambiar Contraseña',
        'change-password.texto-13': 'TU CONTRASEÑA HA SIDO CAMBIADA CON ÉXITO',

        'add-payee.texto-1': 'Agregar nuevo beneficiario',
        'add-payee.texto-2': 'Agregar beneficiario',
        'add-payee.texto-3': 'Por favor, llena la información del beneficiario',
        'add-payee.texto-4': 'Tipo de cuenta',
        'add-payee.texto-5': 'Personal',
        'add-payee.texto-6': 'Negocio',
        'add-payee.texto-7': 'Nombre del beneficiario',
        'add-payee.texto-8': 'Nombre del beneficiario es requerido',
        'add-payee.texto-9': 'Nombre de la compañía',
        'add-payee.texto-10': 'Nombre de la compañía es requerida',
        'add-payee.texto-11': 'Número de cuenta',
        'add-payee.texto-12': 'Ingresa un número de cuenta de 11 dígitos',
        'add-payee.texto-13': 'Código Postal',
        'add-payee.texto-14': 'Código Postal es requerido',
        'add-payee.texto-15': 'CONTINUAR',
        'add-payee.texto-16': 'Por favor, confirma la información del beneficiario',
        'add-payee.texto-17': 'Editar',
        'add-payee.texto-18': 'AÑADIR',
        'add-payee.texto-19': 'Beneficiario añadido con éxito',
        'add-payee.texto-20': 'EL BENEFICIARIO HA SIDO AÑADIDO CON ÉXITO',
        'add-payee.texto-21': 'INGRESA EL NOMBRE DEL NEGOCIO',
        'add-payee.texto-22': 'INGRESA EL NOMBRE DEL BENEFICIARIO',
        'add-payee.texto-23': 'Confirmar beneficiario',
        'add-payee.texto-24': 'CONFIRMAR BENEFICIARIO A AGREGAR',
        'add-payee.texto-25': 'Beneficiario agregado',

        'transfer.texto-1': 'Haz una transferencia',
        'transfer.texto-2': 'Transferir a',
        'transfer.texto-3': 'Selecciona el beneficiario al que transferir',
        'transfer.texto-4': 'Agregar nuevo beneficiario',
        'transfer.texto-5': 'Selecciona un beneficiario',
        'transfer.texto-6': 'Buscar un beneficiario',
        'transfer.texto-7': 'Nombre, número de cuenta',
        'transfer.texto-8': 'Beneficiarios frecuentes',
        'transfer.texto-9': 'Nº de cuenta',
        'transfer.texto-10': 'Personal',
        'transfer.texto-11': 'Negocio',
        'transfer.texto-12': 'Introduce un monto',
        'transfer.texto-13': 'Monto',
        'transfer.texto-14': 'No tienes fondos suficientes para proceder con esta transferencia',
        'transfer.texto-15': 'Observación',
        'transfer.texto-16': 'Transferir de',
        'transfer.texto-17': 'Selecciona una cuenta para transferir desde',
        'transfer.texto-18': 'Confirmar transferencia',
        'transfer.texto-19': 'Selecciona una cuenta ',
        'transfer.texto-20': 'Cuenta de ahorros',
        'transfer.texto-21': 'Cuenta de cheques',
        'transfer.texto-22': 'CONTINUAR',
        'transfer.texto-23': 'TU TRANSFERENCIA HA SIDO COMPLETADA',
        'transfer.texto-24': 'Transferido a',
        'transfer.texto-25': 'Trnsferido desde',
        'transfer.texto-26' : 'Ingresa código recibido',

        'bill-pay.texto-1': 'Realizar un pago',
        'bill-pay.texto-2': 'Pagar a',
        'bill-pay.texto-3': 'Selecciona el beneficiario de la lista a continuación',
        'bill-pay.texto-4': 'Agregar nuevo beneficiario',
        'bill-pay.texto-5': 'Selecciona un beneficiario',
        'bill-pay.texto-6': 'Buscar un beneficiario',
        'bill-pay.texto-7': 'Nombre, número de cuenta',
        'bill-pay.texto-8': 'Beneficiarios frecuentes',
        'bill-pay.texto-9': 'Nº de cuenta',
        'bill-pay.texto-10': 'Negocio',
        'bill-pay.texto-11': 'Introduce un monto',
        'bill-pay.texto-12': 'Monto',
        'bill-pay.texto-13': 'No tienes fondos suficientes para proceder con este pago',
        'bill-pay.texto-14': 'Observación',
        'bill-pay.texto-15': 'Selecciona una cuenta desde la que pagar',
        'bill-pay.texto-16': 'Confirmar pago',
        'bill-pay.texto-17': 'Selecciona una cuenta ',
        'bill-pay.texto-18': 'Cuenta de ahorros',
        'bill-pay.texto-19': 'Cuenta de cheques',
        'bill-pay.texto-20': 'CONTINUAR',
        'bill-pay.texto-21': 'TU PAGO HA SIDO COMPLETADO',
        'bill-pay.texto-22': 'Pagado a',
        'bill-pay.texto-23': 'Pagado desde',
        'bill-pay.texto-24': 'Pagar desde',
    }
}

export default ObjetoLenguage as any;