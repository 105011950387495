import { useMemo, ReactNode, useEffect } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider
} from '@mui/material/styles';
//
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
//Redux
import { useDispatch, useSelector } from 'redux/store';
import { getDefaultFiEnvironment, cleanRespose, localStorageEnvironment } from 'redux/slices/NuDetect';
//Internacionalizar sitio
import { IntlProvider } from 'react-intl';
import ObjetoLenguaje from 'resources/traducciones';
// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const dispatch = useDispatch();
  const { environment } = useSelector((state) => state.nuDetectState);

  useEffect(() =>{
    if(!localStorage.getItem('environment')){
      dispatch(getDefaultFiEnvironment());
    }else{
      dispatch(localStorageEnvironment())
    }
  }, [])

  const themeOptions: ThemeOptions = useMemo(() => ({ palette, typography, breakpoints }), []);

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <IntlProvider locale="en" messages={ObjetoLenguaje[environment?.language]}>
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
    </IntlProvider>
  );
}
